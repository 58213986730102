import React from 'react'

const NewComponent = props => {
    return (
        <div className="beans">
            {props.name}
        </div>
    );
}

export default NewComponent