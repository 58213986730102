import logo from './logo.svg';
import './App.css';
import {useState, useEffect} from 'react';

import NewComponent from './components/NewComponent'

const App = () => {
  const [arr, setArr] = useState(['beans','boods','boids','bonbon','hun'])


  const addBean = () => {

    setArr([...arr,'bean'])
    console.log("beanie");
  }

  useEffect( () =>  {
    alert(arr)
    console.log('mount')
    },[arr]
  ) 

  return (
    <div className="App">
      
      { arr.map(item => <NewComponent name={item}/>) }
    <button onClick={addBean}> Add bean</button>
    </div>
  );
}

export default App;
